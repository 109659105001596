// ====================================================================================================
// ui.js
// shared UI code, jQuery bindings
// ====================================================================================================

;(function( $ )
{
	'use strict';

	// Makes a text area auto-resize on input
	$.fn.makeResponsive = function(){

		var input = $(this),
			holder = this.parent(),
			proxy = $('<div style="white-space:pre-wrap; word-break: break-all; height:auto; display:none"/>'),

			min_height;

		input.detach();
		$(document.body).append(input);
		min_height = input.outerHeight();
		input.detach();
		holder.append(input);

		proxy.attr('class', input.attr('class'));

		input.keyup(function()
		{
			var h;

			proxy.text( input.val() );
			h = proxy.outerHeight();

			if( min_height < h )
				input.css('height', h + 'px');
			else
				input.css('height', min_height + 'px');
		});

		holder.append( proxy );

	};


	// Makes an entire table row clickable
	$('.clickable-row').click(function() {
		window.document.location = $(this).data('href');
	});


	// Makes an image popout when clicked
	$.fn.popout = function(){

		$.each( this, function( index, elem )
		{
			var image = $(elem),
				proxy = $('<div class="popout-holder"><img src="' + image.attr('src') + '"></div>');

			proxy.hide();
			$(document.body).append(proxy);

			image.click(function()
			{
				proxy.fadeIn();
			});

			proxy.click(function()
			{
				proxy.fadeOut();
			});
		});

	};

	// auto-apply all image popout behaviors to the current page
	$('img.popout').popout();

})( jQuery );