// ====================================================================================================
// ribbon-loader.js
// Loads and caches data from Ribbon
// ====================================================================================================

;(function( global )
{
	'use strict';

	var app = global.app = global.app || {};


	// RibbonLoader
	// ------------------------------------------------------------------------------------------
	// Loads and caches ribbon items
	//
	// EVENTS
	// * items -- sent when ribbon items are loaded
	// * user_data -- sent when information about a user is loaded
	// * loading -- sent when the load Ajax request starts
	// * loaded -- sent when the load Ajax request is finished
	// * error -- sent when the load Ajax request fails
	//
	// METHODS
	// * on( event, function( data ){ ... } ) -- listens for the specified event
	// * emit( event, data ) -- emits an event with a specified name
	// ------------------------------------------------------------------------------------------

	function RibbonLoader()
	{
		this._items = [];
		this._user_data = null;
		this._owner = null;
		this._cache_path = 'oneui_ribbon_cache';
		this._ribbon_url = 'https://ribbon.m247.com/ribbon/items.json';

		this.on( 'user_data', function( data )
		{
			this._user_data = data || null;
		});

		this.on( 'items', function( items )
		{
			this._items = items || [];
		});

		this.on( 'loaded', function()
		{
			this._saveCache();
		});
	}

	// Grant the ribbon object the ability to emit and lsiten for events
	Object.inherit( RibbonLoader, app.generics.GenericListener );

	RibbonLoader.prototype.init = function()
	{
		// Fires off loading behaviors
		this
			._loadCache()
			._fetch();

		return this;
	};

	// Reloads the Ribbon instance, optionally clearing the cache
	RibbonLoader.prototype.reload = function( invalidate_cache )
	{
		if( invalidate_cache )
			delete localStorage[ this._cache_path ];

		this
			._loadCache()
			._fetch();

		return this;
	};

	RibbonLoader.prototype.setOwner = function( account )
	{
		this._owner = account;

		return this;
	};

	RibbonLoader.prototype._fetch = function()
	{
		var self = this;

		this.emit('loading');

		$.ajax({
			url : this._ribbon_url + (this._owner ? ('?owner='+this._owner) : ''),
			type : 'GET',
			cache : false,
			dataType : 'JSONP',
			success : function( data )
			{
				if( data.bars )
				{
					data.bars[1].unshift( { name : '(Admin)', url : '#', items : data.bars[0] } );
					self.emit( 'items', data.bars[1] );
				}
				else
					self.emit( 'items', data );

				if( data.info )
					self.emit( 'user_data', data.info );

				self.emit( 'loaded' );
			},
			error : function()
			{
				self.emit( 'error' );
			}
		});

		return this;
	};

	RibbonLoader.prototype._loadCache = function()
	{
		if( ! localStorage[ this._cache_path ] )
			return this;

		var cached = JSON.parse( localStorage[ this._cache_path ] );

		if( cached.user_data )
			this.emit( 'user_data', cached.user_data );
		if( cached.items )
			this.emit( 'items', cached.items );

		return this;
	};

	RibbonLoader.prototype._saveCache = function()
	{
		var json = JSON.stringify({
			items : this._items,
			user_data : this._user_data
		});

		localStorage[ this._cache_path ] = json;

		return this;
	};

	// Exports
	// ------------------------------------------------------------------------------------------

	app.ribbon = {
		RibbonLoader : RibbonLoader
	};

})( window );