// Allows callbacks to be executed based on keys passed in via the page hash

;(function(global)
{
	'use strict';

	var app = global.app = global.app || {},
		handlers = {},
		args = {};

	function _decomposeArgs()
	{
		var raw = window.location.hash.replace('#', '').split(','),
			i = 0, lim = raw.length,
			components;

		args = {};

		for( i; i < lim; i++ )
		{
			components = raw[i].split(':');

			args[ components[0] ] = components[1] || true;

			if( handlers[ components[0] ] )
				handlers[ components[0] ]( components[1] );
		}
	}

	function bind( key, callback )
	{
		handlers[ key ] = callback;

		if( args[ key ] )
			callback( args[key] );
	}

	function once( key, callback )
	{
		var tmp = callback;
		callback = function( val ){
			tmp( val );
			delete handlers[ key ];
		};

		bind( key, callback );
	}

	$(window).on('hashchange', _decomposeArgs);
	$(document).ready(_decomposeArgs);

	app.args = {
		bind : bind,
		once : once
	};

})(window);