// ====================================================================================================
// object-templates.js
// Generic functions which can be inhereted from with Object.inherit()
// Each function provides a generic type of behavior which is useful within the application
// 
// EXAMPLE USAGE
// Object.inherit( constructor, app.generics.GenericListener );
// ====================================================================================================

;(function( global )
{
	'use strict';

	var app = global.app = global.app || {};


	// GenericListener
	// Allows an object to emit and listen for events
	// Example usage: object.on( 'event', function( data ){ ... } );
	// ------------------------------------------------------------------------------------------

	function GenericListener() {}

	GenericListener.prototype.on = function( event, callback )
	{
		if( ! this._handlers )
			this._handlers = {};

		if( this._handlers[ event ] )
			this._handlers[ event ].push( callback.bind( this ) );
		else
			this._handlers[ event ] = [ callback.bind( this ) ];

		return this;
	};

	GenericListener.prototype.off = function( event, callback )
	{
		if( ! this._handlers[ event ] )
			return this;

		var new_handlers = [];

		for( var i = 0, lim = this._handlers[ event ].length; i < lim; i++ )
		{
			if( ! callback || this._handlers[ event ][i] === callback )
				continue;
			else
				new_handlers.push( this._handlers[event][i] );
		}

		this._handlers[ event ] = new_handlers;

		return this;
	};

	GenericListener.prototype.emit = function( event )
	{
		if( ! this._handlers )
			this._handlers = {};

		if( this._handlers[ event ] )
		{
			for( var i = 0, lim = this._handlers[ event ].length; i < lim ; i++ )
			{
				// Allows handlers to return false in order to stop event propagation
				if( this._handlers[ event ][i].apply( this, arguments.slice(1) ) === false )
					break;
			}
		}

		return this;
	};

	// Listens for global events
	GenericListener.prototype.listen = function( event, callback )
	{
		if( ! app._handlers )
			app._handlers = {};

		if( app._handlers[ event ] )
			app._handlers[ event ].push( callback.bind( this ) );
		else
			app._handlers[ event ] = [ callback.bind( this ) ];

		if( ! app.emit )
		{
			app.emit = function( event, data )
			{
				if( app._handlers[ event ] )
				{
					for( var i = 0, lim = app._handlers[ event ].length; i < lim ; i++ )
					{
						if( app._handlers[ event ][i]( data ) === false )
							break;
					}
				}
			};
		}

		return this;
	};


	// Exports
	// ------------------------------------------------------------------------------------------

	app.generics = {
		GenericListener : GenericListener
	};

})( window );