// ====================================================================================================
// sidebar.js
// UI controls for the One UI sidebar
// ====================================================================================================

;(function( global )
{
	'use strict';

	var app = global.app = global.app || {},
		root_config = global.app.config || {};

	if( root_config.disable_sidebar )
		return;

	// ========================================================================================================================
	// Config
	// ========================================================================================================================
	
	var config = {
		fade_time : 300,
		breakpoint_large : 1280,
		breakpoint_small : 768,
		breakpoint_tiny : 512
	};

	// Fontawesome icons for different M247 services
	var icon_lookup = {
		'Billing' : 'credit-card',
		'Accounts' : 'users',
		'Connectivity' : 'road',
		'Circuits' : 'circle-o',
		'Colo' : 'cubes',
		'DNS' : 'tag',
		'Docs' : 'book',
		'Domain Reg' : 'bookmark',
		'Hosting' : 'hdd-o',
		'Inventory' : 'archive',
		'Network' : 'flash',
		'Passwords' : 'user-secret',
		'Servers' : 'sitemap',
		'Service Notes' : 'flag',
		'VPS' : 'server',
	};


	// ========================================================================================================================
	// UI element generators
	// ========================================================================================================================

	function SidebarBlock( id )
	{
		this._id = id || null;

		this._holder = $( String.format('<div class="sidebar-block" id="{0}">', id ) );
		this._div_links = $('<ul class="links">');
		this._div_title = $('<div class="title">');

		this._holder
			.append( this._div_title )
			.append( this._div_links );
	}

	SidebarBlock.prototype.addLink = function( link, url, icon )
	{
		var item = String.format(
				'<li><a href="{0}"><span class="fa fa-fw fa-{1}"></span> {2}</a></li>',
				( url || '#' ),
				( icon || 'circle' ),
				link
			);

		this._div_links
			.append( item );

		return this;
	};

	SidebarBlock.prototype.addSection = function( name, url, children, icon )
	{
		var li = $('<li>'),
			link = $( String.format(
				'<div class="section-heading"><a><span class="fa fa-fw fa-{1}"></span> {2}</a></div>',
				( url || '#' ),
				( icon || 'circle' ),
				name
			) ),
			toggle = $( '<span class="toggle-section"></span>' ),
			toggle_open = '<span class="fa fa-chevron-left">',
			toggle_closed = '<span class="fa fa-chevron-down">',

			open = false;

		toggle.html( toggle_open );

		link.append( toggle );
		li.append( link );

		var subsection = $('<ul class="subsection" style="display:none;">'),
			i = 0, lim = children.length;

		// Include a link to the top-level application
		subsection.append( String.format(
					'<li><a href="{0}">{1}</a></li>',
					url,
					name
				) );

		for( i; i < lim; i++ )
		{
			subsection.append( String.format(
					'<li><a href="{0}">{1}</a></li>',
					children[i].url,
					children[i].name
				));
		}

		li.append( subsection );

		this._div_links
			.append( li );

		link.click(function()
		{
			if( open )
			{
				subsection.hide();
				toggle.html( toggle_open );
				open = false;
			}
			else
			{
				subsection.show();
				toggle.html( toggle_closed );
				open = true;
			}
		});

		return this;
	};

	SidebarBlock.prototype.setTitle = function( title )
	{
		this._div_title.html( title );

		return this;
	};

	SidebarBlock.prototype.attach = function( div )
	{
		$( div ).append( this._holder );

		return this;
	};

	SidebarBlock.prototype.getDOM = function()
	{
		return this._holder;
	};

	SidebarBlock.prototype.empty = function()
	{
		this._div_links.empty();
		this._div_title.empty();

		return this;
	};

	SidebarBlock.prototype.removeLinks = function()
	{
		this._div_links.empty();

		return this;
	};


	// ========================================================================================================================
	// Sidebar Object
	// ========================================================================================================================

	function Sidebar()
	{
		this._state = {
			app_menu_open : false,
			sidebar_open : false,
			scroll_top : 0,
			status : 'idle'
		};

		this.listen('window:ready', this.init);
		this.listen('window:unload', this.shutdown);
		this.listen('window:reload', this.init);
	}

	Object.inherit( Sidebar, app.generics.GenericListener );


	// Lifecycle
	// ------------------------------------------------------------------------------------------

	Sidebar.prototype.init = function()
	{
		if( this._state.status === 'loading' )
			return this;

		this
			._populateDOM()
			._initRibbon()
			._bindHandlers();

		return this;
	};

	Sidebar.prototype.shutdown = function()
	{
		this
			.hideSidebar()
			._unbindHandlers();

		return this;
	};


	// Display functions
	// ------------------------------------------------------------------------------------------

	// SIDEBAR VISIBILITY

	Sidebar.prototype.showSidebar = function()
	{
		this._lockScroll();

		this._dom.sidebar.show();
		this._showFill();
		this._state.sidebar_open = true;

		return this;
	};

	Sidebar.prototype.hideSidebar = function()
	{
		this._unlockScroll();

		this._dom.sidebar.hide();
		this._hideFill();
		this._state.sidebar_open = false;

		return this;
	};

	Sidebar.prototype.toggleSidebar = function()
	{
		if( this._state.sidebar_open )
			this.hideSidebar();
		else
			this.showSidebar();

		return this;
	};

	// TOPBAR SHADOW

	Sidebar.prototype._updateShadow = function()
	{
		if( window.scrollY === 0 )
			this._dom.topbar.removeClass('topbar-scrolled');
		else
			this._dom.topbar.addClass('topbar-scrolled');
		
		return this;
	};

	// SCROLL LOCK

	Sidebar.prototype._lockScroll = function()
	{
		var content = $('#content');

		if( this._state.scroll_locked === true )
			return;

		this._state.scroll_top = $(document.body).scrollTop();

		// Check if to display the scrollbar on the fixed-position div, to prevent the screen from resizing oddly
		if( document.body.scrollHeight > window.innerHeight )
			content.css( 'overflow-y', 'scroll' );

		content.css( 'position', 'fixed' );
		content.css( 'top', (-1 * this._state.scroll_top) + 'px' );

		this._state.scroll_locked = true;

		return this;
	};

	Sidebar.prototype._unlockScroll = function()
	{
		var content = $('#content');

		if( this._state.scroll_locked === false )
			return;

		content.css( 'overflow-y', 'visible' );
		content.css( 'position', 'relative' );
		content.css( 'top', '0px' );

		$(document.body).scrollTop( this._state.scroll_top );

		this._state.scroll_locked = false;

		return this;
	};

	// FILL

	Sidebar.prototype._showFill = function()
	{
		this._dom.fill.fadeIn( config.fade_time );

		return this;
	};

	Sidebar.prototype._hideFill = function()
	{
		this._dom.fill.fadeOut( config.fade_time );

		return this;
	};


	// Populates the DOM with all components relevant to the sidebar
	// ------------------------------------------------------------------------------------------

	Sidebar.prototype._populateDOM = function()
	{
		var body = $(document.body);

		this._dom = {
			topbar : $('#topbar'),
			sidebar_open : $('<div id="sidebar-open-button" class="topbar-button">'),
			sidebar : $('#sidebar'),
			fill : $('<div id="page-fill" style="display:none">')
		};

		// Topbar
		if( ! this._dom.topbar.length )
		{
			this._dom.topbar = $('<div id="topbar">');
			body.append( this._dom.topbar );
		}

		// Sidebar open button
		this._dom.sidebar_open.append( '<img src="/shared/img/menu_icon.png">' );

		this._dom.topbar
			.prepend(this._dom.sidebar_open);

		// Sidebar
		if( ! this._dom.sidebar.length )
		{
			this._dom.sidebar = $('<nav id="sidebar">');
			body.append( this._dom.sidebar );
		}

		// Fill
		body
			.append( this._dom.fill );


		// Pull out topbar links and copy them to the sidebar, to be displayed conditionally
		var link_holder = $('<div class="sidebar-block" id="sidebar-inner-nav">'),
			link_inner = $('<ul class="links">');

		$('#topbar-links > a').each(function( i, link )
		{
			link_inner.append( '<li>' + link.outerHTML + '</li>' );
		});

		link_holder
			.append( '<div class="title">Navigation</div>' )
			.append( link_inner );

		$('#sidebar-user-data').after( link_holder );

		return this;
	};


	// Create the Ribbon loader
	// ------------------------------------------------------------------------------------------

	Sidebar.prototype._initRibbon = function()
	{
		var self = this;

		this._ribbon = new app.ribbon.RibbonLoader();
		this._ribbon_holder = new SidebarBlock();

		var prev_contents = $('#sidebar-applications');

		prev_contents.after( this._ribbon_holder.getDOM() );
		prev_contents.detach();

		// Fetch account parameters
		var owner = global.ribbon_owner || null,
			owner_meta = $('#m247-ribbon-owner');

		if( ! owner && owner_meta.length )
		{
			if( owner_meta.attr('data-owner') )
				owner = owner_meta.attr('data-owner');
			else if( owner_meta.text() )
				owner = owner_meta.text();
		}

		this._ribbon
			.setOwner( owner )
			.on( 'loading', function()
			{
				self._ribbon_holder.setTitle( '<span class="fa fa-fw fa-spin fa-spinner"></span> Loading...' );
				self._state.status = 'loading';
			})
			.on( 'loaded', function()
			{
				self._ribbon_holder.setTitle( 'Applications' );
				self._state.status = 'idle';
			})
			.on( 'error', function()
			{
				self._ribbon_holder.setTitle( 'Load Error!' );
			})
			.on( 'user_data', function( user_data )
			{
				$('#sidebar-user-name').text( user_data.name );
				$('#sidebar-user-organization').text( user_data.desc );
			})
			.on( 'items', function( items )
			{
				self._ribbon_holder.removeLinks();

				for( var i = 0, lim = items.length; i < lim; i++ )
				{
					if( items[i].items )
						self._ribbon_holder.addSection( items[i].name, items[i].url, items[i].items, icon_lookup[ items[i].name ] );
					else
						self._ribbon_holder.addLink( items[i].name, items[i].url, icon_lookup[ items[i].name ] );
				}
			})
			.init();

		return this;
	};


	// Event Handlers
	// ------------------------------------------------------------------------------------------

	Sidebar.prototype._bindHandlers = function()
	{
		var windowObj = $(window);

		this._dom.fill.click( this.hideSidebar );
		this._dom.sidebar_open.click( this.toggleSidebar );

		windowObj.scroll( $.proxy(this._updateShadow, this) );
		windowObj.resize( $.proxy(this.hideSidebar, this) );

		return this;
	};

	Sidebar.prototype._unbindHandlers = function()
	{
		var windowObj = $(window);

		this._dom.fill.off();
		this._dom.sidebar_open.off();

		windowObj.off( 'scroll', this._updateShadow );
		windowObj.off( 'resize', this.hideSidebar );

		return this;
	};


	// ========================================================================================================================
	// document.ready() behavior
	// ========================================================================================================================

	var sidebar = new Sidebar();

	$(document).ready(function()
	{
		app.emit( 'window:ready' );
	});

	app.sidebar = sidebar;


})( window );