// ====================================================================================================
// util.js
// Utility bindings for various application-level functions, created in the global namespace
// ====================================================================================================

;(function()
{
	'use strict';

	// Allows strings of the form '{0} {1}' to be populated with arbitrary input variables
	if (!String.format)
	{
		String.format = function(format)
		{
			var args = Array.prototype.slice.call(arguments, 1);

			return format.replace(/{(\d+)}/g, function(match, number)
			{ 
				return typeof args[number] !== 'undefined' ?
					args[number] :
					match
				;
			});
		};
	}

	// Combines several objects into a new object
	if (!Object.merge)
	{
		Object.merge = function()
		{
			var merged = {};

			var i = 0, lim = arguments.length;

			for( i; i < lim; i++ )
			{
				if( typeof arguments[i] !== 'object' )
					return false;

				for( var key in arguments[i] )
				{
					merged[key] = arguments[i][key];
				}
			}

			return merged;
		};
	}

	// Basic prototypical inheritence
	if (!Object.inherit)
	{
		Object.inherit = function(obj, superObj)
		{
			obj.prototype = new superObj();
			obj.super_ = superObj; 

			return obj;
		};
	}

})();