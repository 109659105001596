// ====================================================================================================
// load-display.js
// Bindings to display an animated load bar, when the document is waiting for ajax requests to finish, or blocking on page load
// ====================================================================================================

;(function(global)
{
	'use strict';

	var app = global.app = global.app || {},
		config = app.config || {};

	if( config.disable_load_display )
		return;

	var load_display = $('<div id="load-display-bar">');

	$(document.body).append( load_display );

	function scroll(){
		load_display.css('background-position', '0px');

		load_display.animate({
			'background-position' : '32px'
		},
		{
			duration : 500,
			queue : false,
			easing : 'linear',
			done : scroll
		});
	}

	scroll();

	function slideDown(){
		load_display.show();

		load_display.animate({
			'top' : '0px'
		},
		{
			duration : 200
		});
	}

	function slideUp(){
		load_display.animate({
			'top' : '-8px'
		},
		{
			duration : 200,
			done : function(){
				load_display.hide();
			}
		});
	}

	$(document).bind('ajaxStart', function()
	{
		slideDown();
	});

	$(document).bind('ajaxStop', function()
	{
		setTimeout( slideUp, 1000 );
	});

	// Show the loadbar when the page spends more than half a second or so on navigation
	$(window).bind('beforeunload', function()
	{
		setTimeout( slideDown, 750 );
	});

})(window);